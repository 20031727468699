<template>
  <div id="app">
    <expired v-if="showExpired"></expired>
    <router-view />
  </div>
</template>

<script>
import Expired from "./components/auth/expired.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: { Expired },
  computed: {
    ...mapGetters(["getUser"]),
    showExpired() {
      var show;
      if (this.$route.name == "login") {
        show = false;
      } else if (this.$route.name == "enroll") {
        show = false;
      } else if (this.$route.name == "NotFound") {
        show = false;
      } else if (this.$route.name == null) {
        show = false;
      } else if (this.$route.name == undefined) {
        show = false;
      } else {
        show = true;
      }
      return show;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
#app {
  font-family: "Montserrat", sans-serif;
}

/* Transtion Animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* Loaing placeholder */
.placeholderLoader {
  overflow: hidden;
  position: relative;
  -webkit-animation-duration: 1.7s;
  animation-duration: 1.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeholderAnimate;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
}
.placeholderLoader-bg-black {
  overflow: hidden;
  position: relative;
  -webkit-animation-duration: 1.7s;
  animation-duration: 1.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeholderAnimate;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #333333 2%, #4d4d4d 18%, #333333 33%);
  background-size: 1300px;
}

@-webkit-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
/* Toggle switch css */
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #34d399;
}

input:focus + .slider {
  box-shadow: 0 0 1px #34d399;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
