import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex)

const vuexPersist = new VuexPersist({
    key: 'app-data',
    storage: window.sessionStorage
})
export default new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        user: null,
        organization: null,
        cancelTokens: [], // axios cancel token
    },

    mutations: {
        // login the user
        login(state, token) {
            state.user = token
        },
        logout(state) {
            state.user = null;
            state.organization = null;
        },
        setOrganization(state, organization) {
            state.organization = organization
        },
        // clear axios cancel tokens
        clearCancelTokens(state) {
            state.cancelTokens = [];
        },
        // add axios cancel tokens
        addCancelTokens(state, token) {
            state.cancelTokens.push(token);
        },

    },
    actions: {
        // clear pending requests
        cancelPendingRequests(context) {
            context.state.cancelTokens.forEach((request) => {
                if (request.cancel) {
                    request.cancel();
                }
            });
            context.commit('clearCancelTokens');
        }
    },
    // store getters
    getters: {
        isAuthenticated: state => !!state.user,
        cancelTokens: state => state.cancelTokens,
        getOrganization: state => state.organization,
        getToken: state => state.user.access_token,
        getUser: state => state.user,
        isPasswordChanged: state => state.user.passwordChanged,
    }
});