import Vue from "vue";
import Router from "vue-router";
import store from "./store";
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/:organization/",
      name: "dashboard",
      component: () => import("./pages/dashboardLayout.vue"),
      meta: {
        title: "Lipa Link",
        requiresAuth: true,
      },
    },
    {
      path: "/company/enroll",
      name: "enroll",
      component: () => import("./pages/auth/enroll.vue"),
      meta: {
        title: "Lipa Link App",
        guest: true,
      },
    },
    {
      path: "/:organization/login",
      name: "login",
      component: () => import("./pages/auth/login.vue"),
      meta: {
        title: "Lipa Link App",
        guest: true,
      },
    },
    {
      path: "/:organization/change-password",
      name: "changePassword",
      component: () => import("./pages/changePassword.vue"),
      meta: {
        title: "Lipa Link App",
        requiresAuth: true,
      },
    },
    {
      path: "/:organization/reset-password",
      name: "resetPassword",
      component: () => import("./pages/resetPassword.vue"),
      meta: {
        title: "Lipa Link App",
        guest: true,
      },
    },

    {
      path: "*",
      name: "NotFound",
      component: () => import("./pages/NotFound.vue"),
    },
  ],
  mode: "history",
  // make sure the the scroll postion is at the top position
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  //base: 'lipa-link-app'
});

// if route requires authenication redirect to auth page
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
    return;
  } else {
    next();
    return;
  }
});

// Authorized Users
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
    return;
  } else {
    next();
    return;
  }
});

// Ensures all pending requests are cancelled on route change

router.beforeEach((to, from, next) => {
  store.dispatch("cancelPendingRequests");
  next();
});

// silence Navigation Dupulicate error
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

export default router;
