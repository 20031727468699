import Vue from 'vue';
import App from './App.vue';
import '@/assets/css/tailwind.css';
import router from './router';
import VueMeta from 'vue-meta'; 
import store from './store';
import axios from 'axios';
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import IdleVue from 'idle-vue';
const eventsHub = new Vue()

Vue.config.productionTip = false;
axios.defaults.baseURL = 'https://api.lipalink.com/';

Vue.use(VueEasytable);
Vue.use(VueMeta);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000
})


// Cancel Token Request Interceptor
axios.interceptors.request.use(function (config) {
  
  //  Generate cancel token source
  let source = axios.CancelToken.source();

  // Set cancel token on this request
  config.cancelToken = source.token;

  // Add to vuex to make cancellation available from anywhere
  store.commit('addCancelTokens', source);

  return config;
}, function (error) {
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
