<template>
  <div class="text-primaryDark">
    <transition name="fade">
      <div
        v-if="isIdle"
        class="bg-black fixed bg-opacity-80 inset-0 toppest h-screen w-screen"
      >
        <div
          class="
            fixed
            center-h-v
            bg-white
            sm:w-1/3
            w-full
            px-10
            pb-10
            shadow-2xl
            rounded-md
          "
        >
          <img class="h-14 mx-auto mt-10" src="@/assets/icons/expired.png" />
          <p class="text-center py-5 text-2xl font-semibold">
            Your session has expired
          </p>
          <p class="text-center text-sm">
            Your session has expired due to inactivity. You will need to login
            again
          </p>
          <div class="w-full flex justify-center mt-8">
            <button
              @click="goToLogin"
              class="
                text-sm
                bg-buttonColor
                hover:bg-primary
                text-white
                w-36
                p-1.5
                rounded-full
                font-medium
              "
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Expired",
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      isIdle: false,
    };
  },
  mounted() {
    console.log(this.$route.name);
  },
  onIdle() {
    this.isIdle = true;
    this.$store.commit("logout");
  },
  methods: {
    goToLogin() {
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.toppest{
  z-index: 99999999;
}
</style>